import React from 'react'
import { RailsForm } from '@support/RailsForm'
import PropTypes from 'prop-types'

import { Form as FinalForm } from 'react-final-form'
import { validate } from '@models/equipment/automatic'
import EquipmentForm from '../Form'
import Form from './Form'

const New = props => {
  const {
    manufacturers,
    part_number_registries,
    sensor_configs,
    o,
    handleError
  } = props

  const {
    kind
  } = props.model

  const sensorConfigs = sensor_configs.map(element => ({
    ...element,
    sensors: element.sensors.map(sensor => {
      sensor.active = true
      return sensor
    })
  }))

  return (
    <RailsForm
      component={FinalForm}
      action="create"
      validate={validate}
      initialValues={{ kind, equipment: {} }}
      render={props => (
        <EquipmentForm
          title={`${I18n.t('actions.add')} ${I18n.t(`v4/equipment.kinds.${kind}`)}`}
        >
          <Form
            manufacturers={manufacturers}
            url={`/v4/o/${o}/equipments/${kind}`}
            part_number_registries={part_number_registries}
            sensor_configs={sensorConfigs}
            method='post'
            {...props}
            action={I18n.t('actions.add')}
            o={o}
            handleError={handleError}
          />
        </EquipmentForm>

      )}
    />
  )
}

New.propTypes = {
  kind: PropTypes.string
}

export default New
