import React from 'react'
import PropTypes from 'prop-types'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

import { useRouter } from '@contexts/RouterProvider'
import { useMenusStatus, MENUS } from '@contexts/MenusStatusProvider'

const ProductsList = ({ classes, products, currentView }) => {
  const { toggleMenu } = useMenusStatus()
  const router = useRouter()

  const getMenuType = () => ({
    weather_forecast: 'weatherForecastProduct',
    satellite_imagery: 'satelliteImageryProduct',
    irrigation_management: 'irrigationProduct'
  })

  const getDataIntercomTarget = () => ({
    weather_forecast: 'product-atmos',
    satellite_imagery: 'product-view',
    irrigation_management: 'product-aqua',
    alert: 'product-alert',
    weather_forecast_notification: 'product-atmoswhats',
    insight: 'product-insights',
    field_notebook: 'product-fieldnotebook',
  })

  const handleOpenMenu = (kind) => {
    const selectedMenu = getMenuType()[kind]
    toggleMenu(MENUS[selectedMenu], true)
  }

  const handleProductClick = (product) => {
    const mapProducts = Object.keys(getMenuType())
    
    const isMapCurrentView = currentView === 'maps/Index' && mapProducts.includes(product.kind);
    const isSatelliteImage = product.kind === 'satellite_imagery';
    if(isMapCurrentView || product.kind === 'weather_forecast'){
      handleOpenMenu(product.kind)
    }
    else if(isSatelliteImage){
      Turbolinks.visit(`${router.baseURL}maps`)
    }
    else{
      router.visit(product.path)
    }
  }

  return (
    <List className={classes.products__menu}>
      {
        products.map(product => (
          <ListItem className={classes.products__item} key={product.label}>
            <a
              onClick={() => handleProductClick(product)}
              className={classes.products__link}
              data-intercom-target={getDataIntercomTarget()[product.kind]}
            >
              <Typography className={classes.products__icon}>
                <span className={classes.products__icon__center}>
                  <img
                    className={classes.product_icon}
                    src={product.icon_url}
                    alt={product.label}
                  />
                </span>
              </Typography>
              <Typography className={classes.products__label}>
                {product.label}
              </Typography>
            </a>
          </ListItem>
        ))
      }
    </List>
  )
}

ProductsList.propTypes = {
  products: PropTypes.array
}

export default ProductsList
