import React from 'react'

import { automaticHeaders, availableManufacturers } from './automatic'
import { implementHeaders } from './implement'
import { tractorHeaders } from './tractor'
import { manualHeaders } from './manual'
import { irrigationHeaders } from './irrigation'

import FullBattery from '@images/equipment/full_battery.svg'
import HalfBattery from '@images/equipment/half_battery.svg'
import LeafwetnessBottom from '@images/equipment/leafwetness_bottom.svg'
import LeafwetnessTop from '@images/equipment/leafwetness_top.svg'
import LowBattery from '@images/equipment/low_battery.svg'
import Moisture from '@images/equipment/moisture.svg'
import RainPrecipitation from '@images/equipment/rain_precipitation.svg'
import InfoLowBattery from '@images/equipment/info_low_battery.svg'
import Soil from '@images/equipment/soil.svg'
import SolarRadiation from '@images/equipment/solar_radiation.svg'
import StatusSensor from '@images/equipment/offline.svg'
import Temperature from '@images/equipment/temperature.svg'
import WindGust from '@images/equipment/wind_gust.svg'
import WindRose from '@images/equipment/wind_rose.svg'
import WindRoseNoInfo from '@images/equipment/wind_rose__no_info.svg'
import WindSpeed from '@images/equipment/wind_speed.svg'
import WindDirection from '@images/equipment/wind_direction.svg'
import Pluviometer from '@images/equipment/pluviometer.svg'
import AutomaticEquipmentOnline from '@images/equipment/automatic.svg'
import AutomaticEquipmentOffline from '@images/equipment/automatic_offline.svg'
import OnlineHydrometer from '@images/equipment/hydrometer.svg'
import OfflineHydrometer from '@images/equipment/offline_hydrometer.svg'

export const fields = [
  'name', 'identifier', 'brand',
  'manufacturer', 'latitude', 'longitude',
  'equipment_type', 'year', 'equipment_model',
  'power_output', 'fuel_capacity',
  'ownership', 'detail', 'pin',
  'irrigation_system_type',
  'irrigation_efficiency',
  'engine_power',
  'irrigation_limitation',
  'irrigation_limit_value',
  'lap_time',
  'maximum_system_capacity',
  'slices',
  'pivot_adequacy_degree',
  'irrigation_engine_power',
  'operations',
  'flow_unit',
  'emitters_flow_rate',
  'spacing_between_plants',
  'spacing_between_lanes',
  'emitters_per_plant',
  'percentage_irrigated_areas'
]

const createEquipmentHeaders = (headers) =>
  headers.map(header => ({
    title: I18n.t(`v4/equipment.attributes.${header}`),
    field: header,
    render: rowData => (header === 'latitude' || header === 'longitude') ? Number(rowData[header]).toFixed(4) : rowData[header]
  }))

export const getEquipmentHeaders = (header) => {
  const equipmentTableHeaders = {
    automatic: createEquipmentHeaders(automaticHeaders),
    implement: createEquipmentHeaders(implementHeaders),
    tractor: createEquipmentHeaders(tractorHeaders),
    manual: createEquipmentHeaders(manualHeaders),
    irrigation: createEquipmentHeaders(irrigationHeaders)
  }

  return equipmentTableHeaders[header]
}

export const deserialize = (equipment) => ({
  ...equipment,
  ...selectTranslations(equipment)
})

const selectTranslations = (equipment) => {
  const translatedEquipment = {}

  if (equipment.ownership) {
    translatedEquipment.ownership = I18n.t(`v4/equipment.enums.ownerships.${equipment.ownership}`)
  }

  if (equipment.equipment_type) {
    translatedEquipment.equipment_kind = equipment.equipment_type
    translatedEquipment.equipment_type = I18n.t(`v4/equipment.enums.types.${equipment.equipment_type}`)
  }

  return translatedEquipment
}

export const isValidLatitude = (latitude) => {
  const lat = Number(latitude)

  return lat >= -90 && lat <= 90
}

export const isValidLongitude = (longitude) => {
  const lon = Number(longitude)

  return lon >= -180 && lon <= 180
}

export const getVariableIcon = (classes) => ({
  pl1: <img src={RainPrecipitation} alt='rain' className={classes.Equipment_icons} />,
  ah1: <img src={Moisture} alt='moisture' className={classes.Equipment_icons} />,
  sr1: <img src={SolarRadiation} alt='solar_radiation' className={classes.Equipment_icons} />,
  at1: <img src={Temperature} alt='temperature' className={classes.Equipment_icons} />,
  ws1: <img src={WindSpeed} alt='wind_speed' className={classes.Equipment_icons} />,
  wd1: <img src={WindDirection} alt='wind_rose' className={classes.Equipment_icons} />,
  offline: <img src={StatusSensor} alt='status_sensor' className={classes.Equipment_icons} />,
  asr1: <img src={SolarRadiation} alt='solar_radiation_average' className={classes.Equipment_icons} />,
  wg1: <img src={WindGust} alt='wind_gust' className={classes.Equipment_icons} />,
  wind_rose_no_info: <img src={WindRoseNoInfo} alt='wind_rose_no_info' width='50px' />
})

export const getIconInfo = {
  pl1: { src: RainPrecipitation, alt: 'rain' },
  ah1: { src: Moisture, alt: 'moisture' },
  sr1: { src: SolarRadiation, alt: 'solar_radiation' },
  asr1: { src: SolarRadiation, alt: 'solar_radiation_average' },
  at1: { src: Temperature, alt: 'temperature' },
  ws1: { src: WindSpeed, alt: 'wind_speed' },
  wd1: { src: WindDirection, alt: 'wind_direction' },
  wg1: { src: WindGust, alt: 'wind_gust' },
  lwl: { src: LeafwetnessBottom, alt: 'leafwetness_bottom' },
  lwh: { src: LeafwetnessTop, alt: 'leafwetness_top' },
  offline: { src: StatusSensor, alt: 'status_sensor' },
  wind_rose: { src: WindRose, alt: 'wind_rose' },
  wind_rose_no_info: { src: WindRoseNoInfo, alt: 'wind_rose_no_info' }
}

export const getInfo = type => {
  switch (type) {
    case true: return I18n.t('v4/equipment.dashboard.info_support')
    case false: return I18n.t('v4/equipment.dashboard.info_data')
    case null: return I18n.t('v4/equipment.dashboard.info_uninstalled')
  }
}
const batteryImage = {
  low: LowBattery,
  half: HalfBattery,
  full: FullBattery,
  info_low: InfoLowBattery
}

export const getBatteryStatus = (value, classes) => {
  const hasHelperText = value === 'info_low' || value === 'low'

  return {
    icon: <img
      src={batteryImage[value]}
      alt={`${value}_battery`}
      className={classes.Equipment_icons}
    />,
    info: hasHelperText && I18n.t(`v4/equipment.dashboard.battery_status.helper_${value}`),
    status: I18n.t(`v4/equipment.dashboard.battery_status.${value}`)
  }
}

const filterDepth = (descriptions = []) => descriptions.filter(description => !!description.depth).
  map(newDrescritpion => ({ ...newDrescritpion, depthValue: newDrescritpion.depth.replace(/\D/gim, '') }))

export const getGroups = descriptions => {
  const newDrescritpions = filterDepth(descriptions)

  return _.groupBy(newDrescritpions, 'short_description')
}

export const getUseCustomPadding = (groups) => Object.keys(groups).length > 2

export const getHeadersEquipmentMenu = (descriptions = [], classes) => {
  const groups = getGroups(descriptions)

  const useCustomPadding = getUseCustomPadding(groups)

  return [
    {
      title: <img src={Soil} alt='soil' className={classes.Equipment_icons} />,
      field: 'value',
      cellStyle: useCustomPadding && customPadding
    },
    ...Object.keys(groups).map(header => ({
      title: header,
      field: header,
      cellStyle: useCustomPadding && customPadding
    }))
  ].flat()
}

export const deserializeEquipmentMenu = (descriptions = []) => {
  const newDrescritpions = filterDepth(descriptions)
  const groupKeys = Object.keys(getGroups(descriptions))

  let addFields = []
  groupKeys.forEach(key => {
    let shortDescriptions = newDrescritpions.filter(description => description.short_description === key)
    let newShortDescriptios = shortDescriptions.map(shortDescription => ({ ...shortDescription, [key]: shortDescription.value }))
    addFields.push(newShortDescriptios)
  })

  const depthGroups = _.groupBy(addFields.flat(), 'depthValue')

  const separateDepthGroups = Object.keys(depthGroups).map(group => depthGroups[group])

  const tableData = separateDepthGroups.map(groups => {
    let variables = {}

    groupKeys.forEach(key => {
      const currentVariable = groups.find(group => Object.keys(group).includes(key)) || {}

      if (!_.isEmpty(currentVariable)) {
        variables[key] = `${currentVariable[key]} ${currentVariable.unit}`
      }
    })

    variables['value'] = groups[0].depth
    return variables
  })

  return tableData
}

export const deserializeEquipments = (data) => {
  !_.isEmpty(data) &&
    data.forEach(equipment => {
      const descriptions = equipment.descriptions

      if (equipment.equipment_status.online) {
        equipment.sortedEquipments = !_.isEmpty(descriptions)
          ? (_.sortBy(equipment.descriptions, ['index']))
          : []

        equipment.equipmentsWithDepth =
          equipment.sortedEquipments
            .filter(description => description.depth)
            .map(_description => ({ ..._description, value: Number(_description.value).toFixed(2) }))

        equipment.equipmentsWithoutDepth = equipment.sortedEquipments.filter(description => !description.depth)

        equipment.groupByDepth = !_.isEmpty(equipment.equipmentsWithDepth)
          ? _.chain(equipment.equipmentsWithDepth)
            .groupBy('depth')
            .map((value, key) => ({
              depth: key, equipments: value
            }))
            .value()
          : []

        equipment.hasWindDirection = !!descriptions.find(description => description.name === 'wd1')
        equipment.hasWindSpeed = !!descriptions.find(description => description.name === 'ws1')
        equipment.batteryInfo = descriptions.find(description => description.name === 'bv1')

        if (!_.isEmpty(equipment.water_meter_measures)) {
          const selectedEquipment = { id: equipment.equipment_id }
          const variableValue = { value: equipment.batteryInfo.value }

          equipment.hydrometer = {
            equipmentMenuData: {
              variableValue,
              hydrometer: equipment.water_meter_measures
            },
            selectedEquipment
          }
        }
      }

      equipment.dateAndTime = getTime(equipment.measured_at)
    })

  return data
}

export const getTime = (dateTime = {}) => {
  const equipmentTime = new Date(dateTime).toLocaleTimeString(I18n.locale)
  const equipmentDate = new Date(dateTime).toLocaleDateString(I18n.locale)
  const currentDate = new Date().toLocaleDateString(I18n.locale)

  const date = new Date()
  const yesterdayDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1).toLocaleDateString()

  if (equipmentDate === currentDate) {
    return {
      infoDate: I18n.t('v4/equipment.dashboard.last_update.today'),
      infoHour: equipmentTime,
      today: true
    }
  } else {
    if (equipmentDate === yesterdayDate) {
      return {
        infoDate: I18n.t('v4/equipment.dashboard.last_update.yesterday'),
        infoHour: equipmentTime,
        today: false
      }
    } else {
      return {
        infoDate: equipmentDate,
        infoHour: equipmentTime,
        today: false
      }
    }
  }
}

export const customPadding = { padding: '4px 4px 4px 16px' }

export const equipments = [
  {
    name: I18n.t('v4/equipment.attributes.automatic'),
    usage: 'automatic'
  },
  {
    name: I18n.t('v4/equipment.attributes.manual'),
    usage: 'manual'
  }
]

export const equipmentIconByStatus = (equipmentStatus, iconOnline, iconOffline) => {
  switch (equipmentStatus) {
    case 'online':
      return iconOnline
    case 'offline':
      return iconOffline
  }
}

export const getEquipmentIcon = (equipment) => {
  if (equipment.kind === 'automatic') {
    if (!_.isEmpty(equipment.tags) && equipment.tags.includes('water_meter')) {
      return equipmentIconByStatus(equipment.status, OnlineHydrometer, OfflineHydrometer)
    }

    return equipmentIconByStatus(equipment.status, AutomaticEquipmentOnline, AutomaticEquipmentOffline)
  }

  return Pluviometer
}
