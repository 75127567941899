import { isValidLatitude, isValidLongitude } from './equipment'

const agrosmartId = 1

export const validate = ({ equipment }) => {
  const fields = [
    'v4_manufacturer_id',
    'name',
    'latitude',
    'longitude',
  ]
  const errors = {}


  if (equipment.v4_manufacturer_id && equipment.v4_manufacturer_id === agrosmartId) {
    fields.push('barcode', 'pin')
  }

  if (equipment.v4_manufacturer_id && equipment.v4_manufacturer_id !== agrosmartId) {
    fields.push('identifier')
  }

  fields.forEach(field => {
    if (!equipment[field]) errors[field] = I18n.t('validations.required')
  })

  if (equipment.latitude && !isValidLatitude(equipment.latitude)) {
    errors.latitude = I18n.t('validations.field_between', { min: -90, max: 90 })
  }

  if (equipment.longitude && !isValidLongitude(equipment.longitude)) {
    errors.longitude = I18n.t('validations.field_between', { min: -180, max: 180 })
  }

  return { equipment: errors }
}

export const automaticHeaders = [
  'name',
  'manufacturer',
  'identifier',
  'latitude',
  'longitude'
]

export const tabs = [
  {
    title: `24 ${I18n.t('v4/equipment.card.hours')}`,
    key: '24hours',
    intercomTag: '24-hours-water-meter'
  },
  {
    title: `48 ${I18n.t('v4/equipment.card.hours')}`,
    key: '48hours',
    intercomTag: '48-hours-water-meter'
  },
  {
    title: `72 ${I18n.t('v4/equipment.card.hours')}`,
    key: '72hours',
    intercomTag: '72-hours-water-meter'
  }
]
