import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'

import TextField from '@ui/Fields/TextField'
import Select from '@ui/Fields/Select'
import DeleteDialog from '@ui/DeleteDialog'

import ConfigurationForm from './sensorConfiguration/Index'
import styles from '@styles/forms'
import httpService from '@services/httpService'

const agrosmartId = 1
class Form extends Component {
  state = {
    customFields: [],
    identification: [],
    open: false,
    sensors_list: [],
    customFieldKinds: [],
    isDialogOpen: false,
    currentCustomField: {
      index: 0,
      name: ''
    }
  }

  getIndex = (value) => value.replace(/\D/g, '')

  applyRegex = (label, kind) => {
    const index = this.getIndex(label)

    if (!index) {
      return false
    } else {
      return new RegExp(`custom_field${index}_${kind}`).test(label)
    }
  }

  getLabel = (data, kind) => {
    const { actionDisabled } = this.props
    let customFieldKinds = []

    const customLabels = Object.keys(data)
      .filter(label => {
        if (this.applyRegex(label, kind)) {
          const index = this.getIndex(label)
          if (!!actionDisabled) {
            customFieldKinds.push({ kind: data[`custom_field${index}_kind`], name: `custom_field${index}_kind` })
          }
          return true
        } else {
          return false
        }
      })

    return {
      customLabels: customLabels.map(custom => data[custom]).filter(field => !!field),
      customFieldKinds
    }
  }

  getCustomLabels = () => {
    const { values, part_number_registries, actionDisabled } = this.props
    let activePartNumber = part_number_registries.filter(partNumber => partNumber.id === values.equipment.v4_part_number_registry_id)[0]

    // Check type of manufacturer and add identification fields
    if (values.equipment.v4_manufacturer_id !== agrosmartId) {
      this.setState({ identification: [activePartNumber.identifier_label] })

      const customs = this.getLabel(activePartNumber, 'label')

      this.setState({ customFields: customs.customLabels, customFieldKinds: customs.customFieldKinds })
    }
    else {
      this.setState({ identification: ['barcode', 'pin'] })
    }
  }

  componentDidMount() {
    const {
      values,
      sensor_configs
    } = this.props

    const sensors_list = sensor_configs.filter(sensor => sensor.id === values['equipment'].sensor_config_id)
    this.setState({ sensors_list })

    values.equipment.v4_part_number_registry_id ? this.setState({ activePartNumber: values.equipment.v4_part_number_registry_id }) : {}

    if (values.equipment.v4_part_number_registry_id && !Object.is(values.equipment.v4_part_number_registry_id, {})) {
      this.getCustomLabels()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { values } = this.props

    if (values.equipment.v4_manufacturer_id != prevProps.values.equipment.v4_manufacturer_id) {
      // Clean fields and values object
      this.setState({ customFields: [], identification: [] })
      values.equipment.barcode = undefined
      values.equipment.pin = undefined
      values.equipment.identifier = undefined
    }

    if (values.equipment.v4_part_number_registry_id != prevProps.values.equipment.v4_part_number_registry_id) {
      this.getCustomLabels()
    }

    if (values.equipment.sensor_config_id !== prevProps.values.equipment.sensor_config_id) {
      const { sensor_configs } = this.props
      const sensors_list = sensor_configs.filter(sensor => sensor.id == this.props.values.equipment.sensor_config_id)
      this.setState({ sensors_list })
    }
  }

  handleSensorsUpdate = (sensors) => {
    const { values } = this.props
    values.equipment.sensors = sensors.flatMap(e => e.active ? [e.id] : [])

    const params =
      sensors
        .filter(sensor => sensor.active)
        .map(sensor => sensor.metadata.params)
        .flat()

    values.equipment.params = params
  }

  handleChangeCustomField = () => {
    const { customFieldKinds, currentCustomField } = this.state
    const { form } = this.props

    customFieldKinds[currentCustomField.index] = ''

    form.getFieldState(`equipment[${currentCustomField.name}]`).change('')

    this.setState({ customFieldKinds, isDialogOpen: false })
  }

  handleSubmit = async (event) => {
    event.preventDefault()

    const { values, actionDisabled, method, url, o, handleError, part_number_registries } = this.props
    const { customFieldKinds } = this.state
    const activePartNumber = part_number_registries.filter(partNumber => partNumber.id === values.equipment.v4_part_number_registry_id)[0]


    if (actionDisabled && !_.isEmpty(customFieldKinds)) {
      Object.keys(values.equipment).forEach(value => {
        if (/custom/.test(value)) {
          const index = this.getIndex(value)
          if (activePartNumber[`custom_field${index}_kind`] === 'Key' && customFieldKinds[index - 1].kind === activePartNumber[`custom_field${index}_kind`]) {
            delete values.equipment[value]
          }
        }
      })
    }

    try {
      await httpService[method](url, values)
      Turbolinks.visit(`/v4/o/${o}/equipments/automatic/`)
    }
    catch (error) {
      handleError(error)
    }
  }

  closeDialog = () => this.setState({ isDialogOpen: false })

  setCurrentCustomField = (index, name) => this.setState({ isDialogOpen: true, currentCustomField: { index, name } })

  render() {
    const {
      action,
      classes,
      manufacturers,
      part_number_registries,
      values,
      sensor_configs,
      o,
      actionDisabled
    } = this.props

    const {
      customFields,
      identification,
      activePartNumber,
      sensors_list,
      customFieldKinds,
      isDialogOpen
    } = this.state

    const partNumberSelected = part_number_registries.filter(partNumber => partNumber.v4_manufacturer_id === values.equipment.v4_manufacturer_id)
    const configurationSelected = sensor_configs.filter(sensor => sensor.part_number_id === values.equipment.v4_part_number_registry_id)

    return (
      <form
        className={classes.form}
        onSubmit={this.handleSubmit}
        data-testid="automatic-equipment-form"
      >
        <main className={classes.form__fields}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <div className={classes.form__field__wrapper}>
                <Field
                  className={classes.form__fieldWithError}
                  component={Select}
                  data={manufacturers}
                  fields={{ id: 'id', name: 'name' }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={I18n.t('v4/equipment.attributes.manufacturer')}
                  margin="normal"
                  name="equipment[v4_manufacturer_id]"
                  required
                  getfullvalue="true"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.form__field__wrapper}>
                <Field
                  className={classes.form__fieldWithError}
                  component={Select}
                  data={partNumberSelected}
                  fields={{ id: 'id', name: 'value' }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={I18n.t('v4/equipment.attributes.part_number')}
                  margin="normal"
                  name="equipment[v4_part_number_registry_id]"
                  required
                  variant="outlined"
                  disabled={Object.is(activePartNumber, {})}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.form__field__wrapper}>
                <Field
                  className={classes.form__fieldWithError}
                  component={TextField}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={I18n.t('v4/equipment.attributes.name')}
                  margin="normal"
                  name="equipment[name]"
                  required
                  type="text"
                  variant="outlined"
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={classes.form__field__wrapper}>
                <Field
                  className={classes.form__fieldWithError}
                  component={TextField}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={I18n.t('v4/equipment.attributes.latitude')}
                  margin="normal"
                  name="equipment[latitude]"
                  inputProps={{ step: '0.000001' }}
                  required
                  type="number"
                  variant="outlined"
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={classes.form__field__wrapper}>
                <Field
                  className={classes.form__fieldWithError}
                  component={TextField}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={I18n.t('v4/equipment.attributes.longitude')}
                  margin="normal"
                  name="equipment[longitude]"
                  inputProps={{ step: '0.000001' }}
                  required
                  type="number"
                  variant="outlined"
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.form__field__wrapper}>
                <Field
                  className={classes.form__fieldWithError}
                  component={TextField}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={I18n.t('v4/equipment.attributes.detail')}
                  margin="normal"
                  name="equipment[detail]"
                  multiline
                  variant="outlined"
                />
              </div>
            </Grid>

            {
              identification.map(id =>
                <Grid item xs={12} key={id}>
                  <div className={classes.form__field__wrapper}>
                    <Field
                      className={classes.form__fieldWithError}
                      component={TextField}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label={values.equipment.v4_manufacturer_id === agrosmartId ? I18n.t(`v4/equipment.attributes.${id}`) : id}
                      margin="normal"
                      name={values.equipment.v4_manufacturer_id === agrosmartId ? `equipment[${id}]` : 'equipment[identifier]'}
                      multiline
                      variant="outlined"
                      required
                    />
                  </div>
                </Grid>
              )
            }

            {
              customFields.map((field, index) =>
                <Grid item xs={12} key={field}>
                  <div className={`${classes.form__field__wrapper} ${classes.Form_Wrapper_CustomFields}`}>
                    <Field
                      className={classes.form__fieldWithError}
                      component={TextField}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label={field}
                      margin="normal"
                      name={`equipment[custom_field${index + 1}]`}
                      multiline
                      variant="outlined"
                      disabled={!_.isEmpty(customFieldKinds) ? (customFieldKinds[index].kind === 'Key') : !!actionDisabled}
                    />

                    {
                      actionDisabled && customFieldKinds[index].kind === 'Key' &&
                      <Button
                        className={classes.form__action}
                        color='secondary'
                        onClick={() => this.setCurrentCustomField(index, `custom_field${index + 1}`)}
                      >
                        {I18n.t('actions.edit')}
                      </Button>
                    }
                  </div>
                </Grid>
              )
            }

          </Grid>
          <Grid item xs={12}>
            <div className={classes.form__field__wrapper}>
              <Field
                className={classes.form__fieldWithError}
                component={Select}
                data={configurationSelected}
                fields={{ id: 'id', name: 'name' }}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={I18n.t('v4/configuration.show.title')}
                margin="normal"
                name="equipment[sensor_config_id]"
                required
                variant="outlined"
              />
            </div>
          </Grid>

          {
            !_.isEmpty(sensors_list) &&
            <ConfigurationForm
              handleSensorsUpdate={this.handleSensorsUpdate}
              sensors_list={sensors_list}
            />
          }

          <DeleteDialog
            dialogText={I18n.t('v4/equipment.attributes.warning_text')}
            onCancel={this.closeDialog}
            onClose={this.closeDialog}
            handleDelete={this.handleChangeCustomField}
            open={isDialogOpen}
            actionText={I18n.t('confirm_dialog.confirmText')}
          />
        </main>

        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {`${action} ${I18n.t('v4/equipment.kinds.automatic')}`}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            href={`/v4/o/${o}/equipments/automatic`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </form >
    )
  }
}

const Styles = (theme) => ({
  ...styles(theme),
  Form_Wrapper_CustomFields: {
    display: 'flex',
    alignItems: 'baseline'
  }
})

Form.defaultProps = {
  manufacturers: [],
  part_number_registries: [],
  sensor_configs: [],
  sensors: []
}

Form.propTypes = {
  action: PropTypes.string
}

export default withStyles(Styles)(Form)
